import React, { useState, useEffect, useRef } from "react";
import sound from "../assets/sound/beats.mp3";
import { Button } from "@material/react-button";
import MaterialIcon from "@material/react-material-icon";

const AudioContext = window.AudioContext || window.webkitAudioContext;
export const Audio = ({ updateContent }) => {
  const audioRef = useRef(null);
  const fileRef = useRef(null);
  const [isBegin, setIsBegin] = useState(false);
  useEffect(() => {
    if (isBegin && audioRef && fileRef) {
      // console.log("in sound statement");
      //Prepare sound context
      const content = new AudioContext();
      if (!content) return "Web Audio API is not supported in this browser";
      const analyser = content.createAnalyser();
      analyser.fftSize = 512;
      const bufferLength = analyser.frequencyBinCount;
      let audio = audioRef.current;
      var src = content.createMediaElementSource(audio); //create src inside ctx
      src.connect(analyser); //connect analyser node to the src
      analyser.connect(content.destination); // connect the destination
      //When audio file uploaded
      let file = fileRef.current;
      file.onchange = function() {
        let files = this.files;
        audio.src = URL.createObjectURL(files[0]);
        audio.load();
        audio.play();
      };

      updateContent({
        analyser: analyser,
        dataArray: new Uint8Array(bufferLength)
      });
    }
  }, [isBegin, updateContent]);
  const overlayStyle = {
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(255,255,255,0.6)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  };
  const fileStyle = {
    width: "0.1px",
    height: "0.1px",
    opacity: "0",
    overflow: "hidden",
    position: "absolute",
    zIndex: "1"
  };
  const uploadStyle = {
    display: "block",
    margin: "20px auto"
  };
  const soundSectionStyle = {
    position: "fixed",
    bottom: "20px",
    width: "100vw",
    height: "200px"
  };
  return (
    <div>
      {isBegin ? (
        <div style={soundSectionStyle}>
          <Button
            htmlFor="audio-file"
            className="file"
            outlined
            style={uploadStyle}
          >
            <label>
              Upload Audio File
              <input
                type="file"
                ref={fileRef}
                style={fileStyle}
                accept="audio/*"
              />
            </label>
          </Button>
          <audio
            controls
            loop
            autoPlay
            src={sound}
            preload="none"
            ref={audioRef}
            style={{ margin: "auto", display: "block" }}
          ></audio>
        </div>
      ) : (
        <div style={overlayStyle}>
          <Button
            style={{ backgroundColor: "white" }}
            outlined
            onClick={() => {
              setIsBegin(true);
            }}
          >
            Begin
          </Button>
          <br />
          <div
            style={{
              color: "#6200ee",
              fontSize: "12px",
              verticalAlign: "middle"
            }}
          >
            Support browsers:
            <br />
            <MaterialIcon icon="check" style={{ fontSize: "16px" }} />
            Chrome
            <br />
            <MaterialIcon icon="check" style={{ fontSize: "16px" }} />
            Firefox
            <br />
            <MaterialIcon icon="check" style={{ fontSize: "16px" }} />
            Edge
            <br />
            <MaterialIcon icon="check" style={{ fontSize: "16px" }} />
            Opera > 40
            <br />
            <MaterialIcon icon="close" style={{ fontSize: "16px" }} />
            Safari
            <br />
            <MaterialIcon icon="close" style={{ fontSize: "16px" }} />
            IE
            <br />
          </div>
        </div>
      )}
    </div>
  );
};
