import React, { Fragment } from "react";
import "./App.css";
import "@material/react-button/dist/button.css";
import { Three } from "./components/Three";

function App() {
  return (
    <Fragment>
      <Three />
    </Fragment>
  );
}

export default App;
