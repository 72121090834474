import * as THREE from "three";
import GLTFLoader from "three-gltf-loader";
const FBXLoader = require("three-fbxloader-offical");
const loader = new FBXLoader();
const tLoader = new THREE.TextureLoader();
export const createCity = scene => {
  let city;
  loader.load("./models/city/city.fbx", function(object3d) {
    city = object3d;
    city.scale.multiplyScalar(0.1);
    city.traverse(function(child) {
      try {
        tLoader.load(`./models/city/Palette.jpg`, texture => {
          if (child instanceof THREE.Mesh) {
            child.material.map = texture;
            child.material.color = { r: 1, g: 1, b: 1 };
            child.material.needsUpdate = true;
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
    // city.children[0].position.set(0, -7, 0);
    city.name = "city";
    scene.add(city);
  });
  return city;
};

export const createBaby = (scene, mixers, setGeo, geo) => {
  let loader = new GLTFLoader();

  // Load a glTF resource
  loader.load(
    // resource URL
    "./models/baby/baby_dance2.glb",
    // called when the resource is loaded
    function(gltf) {
      const baby = gltf.scene;
      scene.add(baby);
      baby.scale.multiplyScalar(15);
      baby.children[0].position.set(0, -0.5, 0);
      //   baby.position.set(0, 0, 0);
      baby.rotation.y = Math.PI / 2;
      let mixer = new THREE.AnimationMixer(baby);
      mixer
        .clipAction(gltf.animations[0])
        .setDuration(1)
        .play();
      mixers.push(mixer);
      baby.name = "baby";
      setGeo({ ...geo, baby: baby });
      baby.matrixAutoUpdate = true;
    },
    // called while loading is progressing
    function(xhr) {
      console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
    },
    // called when loading has errors
    function(error) {
      console.log("An error happened: ", error);
    }
  );
};
export const createAnimals = (scene, geo, setGeo) => {
  let loader = new GLTFLoader();

  // Load a glTF resource
  loader.load(
    // resource URL
    "./models/animals/animals4.glb",
    // called when the resource is loaded
    function(gltf) {
      const mesh = gltf.scene;
      mesh.position.set(4, -0.5, 0);
      mesh.name = "animals";
      mesh.matrixAutoUpdate = true;
      geo.animals = mesh;
      setGeo({ ...geo });
      scene.add(mesh);
    },
    // called while loading is progressing
    function(xhr) {
      console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
    },
    // called when loading has errors
    function(error) {
      console.log("An error happened: ", error);
    }
  );
};
