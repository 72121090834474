/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, Fragment } from "react";
import * as THREE from "three";
import OrbitControls from "three-orbitcontrols";
import { createAnimals } from "../assets/geometry";
import { TweenMax, Circ } from "gsap";
import { Audio } from "./Audio";

// const KeyboardState = require("../assets/KeyboardState");
// let keyboard = new KeyboardState();
const clock = new THREE.Clock();
const mixers = [];

export const Three = () => {
  // console.log("loadFile");

  const containerRef = useRef(null);

  const [geo, setGeo] = useState({});
  const [scene] = useState(new THREE.Scene());
  const [audioCtx] = useState({
    analyser: null,
    dataArray: null
  });
  const [container, setContainer] = useState(null);
  const [renderer] = useState(
    new THREE.WebGLRenderer({ alpha: true, antialias: true })
  );
  const [camera] = useState(
    new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      1,
      4000
    )
  );
  const [originalHeight, setOriginalHeight] = useState([]);

  useEffect(() => {
    // console.log("Check Animal");
    console.log(geo);
    if (geo.animals) {
      geo.animals.children.forEach((el, i) => {
        originalHeight.push(el.position.y);
      });
    }
    setOriginalHeight(originalHeight);
  }, [geo]);

  useEffect(() => {
    // console.log("Initialize");
    window.addEventListener("resize", onWindowResize, false);
    init();
    animate();
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  useEffect(() => {
    if (container && !container.domElement) {
      container.appendChild(renderer.domElement);
    }
  }, [container]);

  renderer.shadowMap.enabled = true;
  camera.position.set(0, 0, -30);
  // camera.matrixAutoUpdate = true;
  //Setup Control
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.dampingFactor = 0.25;
  controls.enableZoom = true;

  const init = () => {
    renderer.setClearColor(0xffffff, 0);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);

    setContainer(containerRef.current);

    //Setup Light
    const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 1);
    hemiLight.position.set(0, 200, 2);
    scene.add(hemiLight);
    const hemiLightHelper = new THREE.HemisphereLightHelper(hemiLight, 10);
    scene.add(hemiLightHelper);

    //Load Charactors
    createAnimals(scene, geo, setGeo);
  };

  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  const update = () => {
    const dt = clock.getDelta();
    for (var i = 0; i < mixers.length; i++) {
      mixers[i].update(dt);
    }
    // Get FFT data
    const { analyser, dataArray } = audioCtx;
    if (analyser && dataArray) {
      analyser.getByteFrequencyData(dataArray);
      let animalArray = [];
      const step = parseInt(dataArray.length / 19);
      let stepBuffer = 0;
      dataArray.forEach((el, i) => {
        if ((i + 1) % step !== 0) {
          stepBuffer += el;
        } else {
          animalArray.push(stepBuffer / step);
          stepBuffer = 0;
        }
      });

      const { animals } = geo;
      if (animals) {
        animals.children.forEach((el, i) => {
          if (
            animalArray[i] > 120 &&
            Math.abs(el.position.y - originalHeight[i]) < 0.01
          ) {
            TweenMax.to(el.position, 0.2, {
              y: animalArray[i] / 50,
              ease: Circ.out,
              yoyo: true
            });
          } else {
            TweenMax.to(el.position, 0.2, {
              y: originalHeight[i],
              ease: Circ.out,
              yoyo: true
            });
          }
        });
      }
    }
  };

  const updateContent = content => {
    audioCtx.analyser = content.analyser;
    audioCtx.dataArray = content.dataArray;
  };

  const animate = () => {
    update();
    renderer.setClearColor(0xffe0f9, 1);
    requestAnimationFrame(() => {
      animate();
    });

    controls.update();
    renderer.render(scene, camera);
  };
  return (
    <Fragment>
      <div
        id="animation-container"
        ref={containerRef}
        style={{ zIndex: "-1" }}
      ></div>
      <Audio updateContent={updateContent} />
    </Fragment>
  );
};
